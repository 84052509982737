import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import LeftNavMenuItem from './left-nav-menuItem';
import colors from '../../themes/main-colors';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
  },

  appMenu: {
    '& .MuiTypography-root': {
      fontSize: theme.spacing(2),
      color: colors.sparkBlue,
      fontWeight: 900,
      display: 'grid',
    },
    '& .MuiListItem-root': {
      borderBottom: '1px solid #d7d7d7',
      padding: theme.spacing(0, 2),
      '& .MuiListItemText-root': {
        padding: theme.spacing(1, 0),
        margin: 0,
      },
    },
    '& .MuiDivider-flexItem': {
      right: 19,
      position: 'relative',
      marginLeft: theme.spacing(0.75),
    },
    '& .MuiListItemIcon-root': {
      minWidth: 30,
      position: 'absolute',
      right: 0,
    },
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: '#D2E3F0',
    },
    '& .expandItem .expandItem .expandItem .expandItem > li': {
      paddingLeft: theme.spacing(3),
    },
  },

  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 300,
    padding: 0,
    boxShadow: '0px 3px 6px #00002930',
    background: colors.white,
    color: 'blue',
    borderRadius: 5,
    '& .MuiListSubheader-root': {
      padding: 0,
    },
  },

  heading3: {
    margin: theme.spacing(0),
    color: colors.noirBlur,
    fontSize: theme.spacing(2.5),
    paddingLeft: theme.spacing(2.5),
  },
}));

interface IPanel {
  labels?: string;
  id?: string;
  slug: string;
  url?: string;
  items?: Array<IPanel>;
}

interface IProps {
  selectedItem?: any;
  leftNavMenuItems?: any;
  items?: Array<IPanel>;
  labels?: string;
  id?: string;
  slug?: string;
  url?: string;
}

const LeftNavMenu = (props: IProps) => {
  const classes = useStyles();

  return (
    <div className={clsx('App', classes.root)}>
      <List
        className={classes.drawerPaper}
        aria-labelledby="nested-list-subheader"
      >
        <List className={`sectionNavigation ${classes.appMenu}`} disablePadding>
          {props.leftNavMenuItems?.map((item: any, id: any) => (
            <LeftNavMenuItem
              {...item}
              key={id}
              selectedItem={props.selectedItem}
            />
          ))}
        </List>
      </List>
    </div>
  );
};

export default LeftNavMenu;
